import React from 'react';
import logoSrc from './img/logo.svg';

function Content() {
    const company = {
        name: 'Digitibox',
        address: 'https://digitibox.pl/',
        email: 'dan@digitibox.pl'
    }

    const logo = {
        src: logoSrc,
        size: 70
    };

    return (
        <div>
            <header>
                <img src={ logo.src } width={ logo.size } alt={ company.name } />
                <h1>{ company.name }</h1>
            </header>
            <main>
                <p>Mobile and web applications development, Krakow.</p>
            </main>
        </div>
    );
}

export default Content;
